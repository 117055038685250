import {
  CellType,
  DisplayTableColumnId,
  NoCodeCellDataframe,
  SharedFilterAutoLinks,
  SharedFilterManualLinks,
  SharedFilterPredicateOp,
  buildParameterFilter,
  getDataframeName,
  isFilterableCellType as isFilterableCellTypeInternal,
  parseJsonSerializedParameterValueContents,
} from "@hex/common";

import {
  CellContentsMP,
  CellMP,
} from "../../redux/slices/hexVersionMPSlice.js";
import { getDataFramesForCell } from "../cell/utils/getDataframesForCell.js";

import { SharedFilter } from "./types.js";

/**
 * This only returns dataframes that are relevant for shared filters
 */
export function getSharedFilterableDataFramesForCell(
  cellContents: CellContentsMP,
): readonly NoCodeCellDataframe[] {
  if (cellContents.__typename === "SqlCell") {
    return [cellContents.resultVariable];
  }

  if (isFilterableCellType(cellContents.__typename)) {
    return getDataFramesForCell(cellContents);
  }
  return [];
}

/**
 * This only returns dataframe names that are relevant for shared filters
 */
export function getSharedFilterableDataFrameNamesForCell(
  cellContents: CellContentsMP,
): readonly string[] {
  return getSharedFilterableDataFramesForCell(cellContents).map((df) =>
    getDataframeName(df),
  );
}

export function isSharedFilterConfigured(sf: {
  autoLinks: SharedFilterAutoLinks | null;
  manualLinks: SharedFilterManualLinks | null;
  operator: SharedFilterPredicateOp | null;
}): boolean {
  return (
    sf.operator != null &&
    ((sf.autoLinks != null && sf.autoLinks.length > 0) ||
      (sf.manualLinks != null &&
        sf.manualLinks.filter((ml) => !ml.isExclude).length > 0))
  );
}

export function isSharedFilterActive(sf: SharedFilter): boolean {
  if (!isSharedFilterConfigured(sf) || sf.value == null) {
    return false;
  }

  return (
    buildParameterFilter({
      column: DisplayTableColumnId.check(sf.name ?? ""),
      operator: sf.operator,
      value: parseJsonSerializedParameterValueContents(sf.value),
    }) != null
  );
}

export function isFilterableCellType(
  type: CellContentsMP["__typename"] | CellMP["cellType"],
): boolean {
  return (
    type === "ChartCell" ||
    type === "DisplayTableCell" ||
    type === "SqlCell" ||
    type === "PivotCell" ||
    type === "ExploreCell" ||
    isFilterableCellTypeInternal(type as CellType)
  );
}
