import { OrgId } from "@hex/common";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

import { Routes } from "../../route/routes.js";
import { Avatar } from "../user/Avatar.js";

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;

const OrgImageDiv = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 10px;

  color: ${({ theme }) => theme.highlightColor};

  font-size: ${({ theme }) => theme.fontSize.EXTRA_LARGE};

  background-color: ${({ theme }) => rgba(theme.highlightColor, 0.1)};

  border: 1px solid ${({ theme }) => rgba(theme.highlightColor, 0.3)};

  border-radius: ${({ theme }) => theme.borderRadius};
`;

export interface OrgAvatarProps {
  hasCustomAppIcon: boolean;
  orgId: OrgId;
  orgName: string;
  size?: number;
}

export const OrgAvatar: React.ComponentType<OrgAvatarProps> = React.memo(
  function OrgAvatar({ hasCustomAppIcon, orgId, orgName, size = 30 }) {
    const appIcon = hasCustomAppIcon ? Routes.APP_ICON.getUrl({ orgId }) : null;
    return appIcon != null ? (
      <StyledAvatar
        active={true}
        altText={orgName}
        imageUrl={appIcon}
        shape="square"
        size={size}
        text={orgName}
      />
    ) : (
      <OrgImageDiv>{orgName.charAt(0)}</OrgImageDiv>
    );
  },
);
