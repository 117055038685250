import { Classes } from "@blueprintjs/core";
import {
  ALL_PROJECT_ROLES,
  DOCS_LINKS,
  ProjectRole,
  humanReadableProjectRole,
  projectRoleDescription,
} from "@hex/common";
import React, { ReactNode, useCallback, useMemo } from "react";
import styled from "styled-components";

import {
  HexButton,
  HexMenu,
  HexMenuFooter,
  HexPopover,
  HexTooltip,
} from "../../hex-components";
import { useToggleState } from "../../hooks/useToggleState";
import { DocsLink } from "../common/DocsLink";
import { RoleMenuItem } from "../common/RoleMenuItem";
import { Heading } from "../Heading";
import { InfoIcon, SingleChevronDownIcon } from "../icons/CustomIcons";

const RoleButton = styled(HexButton)`
  justify-content: space-between;

  outline: none;

  ${({ small }) => small && `padding-right: 3px;`}
`;

const RoleGroupHeader = styled(Heading)`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 0 0 8px;
`;

const StyledMenu = styled(HexMenu)`
  min-width: 90px;
  max-width: 290px;
  padding: 7px;

  .${Classes.MENU_DIVIDER} {
    margin: 7px 8px;
  }
`;

const BoldSpan = styled.span`
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

interface RoleDropdownProps {
  className?: string;
  minRole: ProjectRole;
  maxRole: ProjectRole;
  canShare?: boolean;
  isComponent: boolean;
  explorerRoleCanViewChange: boolean;
  additionalActions?: ReactNode;
  small?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  selectedRole: ProjectRole | null;
  onSelectRole:
    | ((newRole: ProjectRole | null) => void)
    | ((newRole: ProjectRole) => void);
  roleAdditionalActions?: Partial<Record<ProjectRole, ReactNode>>;
  labelPrefix?: string;
  // when the dropdown is determining the role for a project/component in a collection, we should also
  // show the option for "No additional access". Use this boolean if we should include that role, and format the
  // dropdown appropriately
  isInCollectionsContext?: boolean;
  "data-cy"?: string;
}

export const RoleDropdown: React.ComponentType<RoleDropdownProps> = React.memo(
  function RoleDropdown({
    additionalActions,
    canShare = true,
    className,
    "data-cy": dataCy,
    disabled = false,
    disabledTooltip,
    explorerRoleCanViewChange,
    isComponent,
    isInCollectionsContext,
    labelPrefix,
    maxRole,
    minRole,
    onSelectRole,
    roleAdditionalActions = {},
    selectedRole,
    small = false,
  }: RoleDropdownProps) {
    const [isMenuOpen, , { setFalse: closeMenu, toggle: toggleMenuOpen }] =
      useToggleState(false);

    const maxIndex = ALL_PROJECT_ROLES.indexOf(maxRole);
    const minIndex = ALL_PROJECT_ROLES.indexOf(minRole);
    // this array is in reverse order
    const allowedRoles = ALL_PROJECT_ROLES.slice(maxIndex, minIndex + 1);
    const nonAppUserRoles = allowedRoles.filter(
      (role) => role !== ProjectRole.APP_USER,
    );
    const appUserRoles = allowedRoles.filter(
      (role) => role === ProjectRole.APP_USER,
    );

    const entityType = isComponent ? "component" : "project";

    const roleDescriptions = useMemo(
      () => ({
        OWNER: {
          label: humanReadableProjectRole(
            ProjectRole.OWNER,
            isComponent,
            explorerRoleCanViewChange,
          ),
          description: projectRoleDescription(
            ProjectRole.OWNER,
            isComponent,
            explorerRoleCanViewChange,
          ),
        },
        EDITOR: {
          label: humanReadableProjectRole(
            ProjectRole.EDITOR,
            isComponent,
            explorerRoleCanViewChange,
          ),
          description: projectRoleDescription(
            ProjectRole.EDITOR,
            isComponent,
            explorerRoleCanViewChange,
          ),
        },
        VIEWER: {
          label: humanReadableProjectRole(
            ProjectRole.VIEWER,
            isComponent,
            explorerRoleCanViewChange,
          ),
          description: projectRoleDescription(
            ProjectRole.VIEWER,
            isComponent,
            explorerRoleCanViewChange,
          ),
        },
        APP_USER: {
          label: humanReadableProjectRole(
            ProjectRole.APP_USER,
            isComponent,
            explorerRoleCanViewChange,
          ),
          description: projectRoleDescription(
            ProjectRole.APP_USER,
            isComponent,
            explorerRoleCanViewChange,
          ),
        },
        // This is a special type that is not classified as an actual project role, as no roles are given to the project or component.
        NO_ADDED_GRANT: {
          label: "No additional access",
          description: `Collection members and managers will not be granted any additional access.`,
        },
      }),
      [explorerRoleCanViewChange, isComponent],
    );

    const currentRoleLabel = useMemo(() => {
      if (selectedRole == null) {
        return roleDescriptions["NO_ADDED_GRANT"].label;
      }

      const label = roleDescriptions[selectedRole].label;
      return labelPrefix ? (
        <>
          {labelPrefix}
          <BoldSpan>{label}</BoldSpan>
        </>
      ) : (
        label
      );
    }, [labelPrefix, roleDescriptions, selectedRole]);

    const selectRole = useCallback(
      (role) => {
        onSelectRole(role);
        closeMenu();
      },
      [onSelectRole, closeMenu],
    );

    const permissionsDocsLinks = isComponent
      ? DOCS_LINKS.ComponentsPermissions
      : DOCS_LINKS.Permissions;

    const missingPermissionsTooltip = `You do not have the permissions to share this ${entityType}.`;
    const roleList = useMemo(() => {
      const nonAppUserRoleMenuItems = (
        <>
          {nonAppUserRoles.map((role, key) => (
            <HexTooltip
              key={key}
              content={missingPermissionsTooltip}
              disabled={canShare}
              placement="right"
            >
              <RoleMenuItem<ProjectRole>
                key={key}
                additionalActions={roleAdditionalActions[role] ?? null}
                currentRole={selectedRole}
                description={roleDescriptions[role].description}
                disabled={!canShare}
                label={roleDescriptions[role].label}
                role={role}
                onSelectRole={selectRole}
              />
            </HexTooltip>
          ))}
        </>
      );

      const appUserRoleMenuItems = (
        <>
          {appUserRoles.length > 0 &&
            appUserRoles.map((role, key) => (
              <HexTooltip
                key={key}
                content={disabledTooltip}
                disabled={canShare}
                placement="right"
              >
                <RoleMenuItem<ProjectRole>
                  key={key}
                  additionalActions={roleAdditionalActions[role] ?? null}
                  currentRole={selectedRole}
                  description={roleDescriptions[role].description}
                  disabled={!canShare}
                  label={roleDescriptions[role].label}
                  role={role}
                  onSelectRole={selectRole}
                />
              </HexTooltip>
            ))}
        </>
      );
      if (isInCollectionsContext) {
        return (
          <>
            <RoleGroupHeader renderAs="h1" styleAs="h5">
              Collection access{" "}
              <HexTooltip
                content={`Additional ${entityType} level access that all collection managers & members will be granted.`}
                placement="bottom"
              >
                <InfoIcon />
              </HexTooltip>
            </RoleGroupHeader>
            {nonAppUserRoleMenuItems}
            {appUserRoleMenuItems}
          </>
        );
      } else {
        return (
          <>
            {nonAppUserRoleMenuItems}
            {appUserRoleMenuItems}
          </>
        );
      }
    }, [
      appUserRoles,
      canShare,
      disabledTooltip,
      missingPermissionsTooltip,
      entityType,
      nonAppUserRoles,
      roleAdditionalActions,
      roleDescriptions,
      selectRole,
      selectedRole,
      isInCollectionsContext,
    ]);

    return (
      <HexPopover
        captureDismiss={true}
        className={className}
        content={
          <>
            <StyledMenu>
              {roleList}
              {isInCollectionsContext && (
                <RoleMenuItem<ProjectRole>
                  currentRole={selectedRole}
                  description={roleDescriptions["NO_ADDED_GRANT"].description}
                  label={roleDescriptions["NO_ADDED_GRANT"].label}
                  role={null}
                  onSelectRole={selectRole}
                />
              )}
              {additionalActions}
            </StyledMenu>
            <HexMenuFooter>
              <DocsLink to={permissionsDocsLinks}>
                Learn more about permissions
              </DocsLink>
            </HexMenuFooter>
          </>
        }
        isOpen={isMenuOpen}
        minimal={true}
        rootBoundary="viewport"
        onClose={closeMenu}
      >
        <HexTooltip
          content={disabledTooltip}
          disabled={!disabledTooltip || !disabled}
        >
          <RoleButton
            active={isMenuOpen}
            data-cy={dataCy}
            disabled={disabled}
            minimal={true}
            rightIcon={<SingleChevronDownIcon />}
            small={small}
            onClick={toggleMenuOpen}
          >
            {currentRoleLabel}
          </RoleButton>
        </HexTooltip>
      </HexPopover>
    );
  },
);
